  <template>
    <div>
        <template v-if="node.hasOwnProperty('children') && node.children.length">
          <div class="row align-items-center pl-4 py-0 text-uppercase"  v-if="node.code.length > 2">
            <span>({{node.code}}) -{{node.description}}</span>
          </div>
          <template v-for="(node,index) in node.children">
            <PscActionCodeComponent :node="node" :psc-code="pscCode" :dept="dept+index" @setPscCode="(item)=>$emit('setPscCode',item)"></PscActionCodeComponent>
          </template>
        </template>
        <template v-else>
          <div class="form-check form-check-inline align-items-center d-flex pb-1 pl-3">
            <input class="form-check-input expand-25-percent mr-2" type="checkbox"
                   :value="node.id"
                   :id="node.code"
                   :disabled="!(pscCode!==null && pscCode.id != node.id)"
                   @change="selectPscCode(node)"
                   :checked="pscCode.id==node.id"
            >
            <label class="form-check-label text-uppercase" :for="node.code" >({{node.code}}) - {{ node.description }}</label>
          </div>
        </template>
    </div>
  </template>
  <script>
  export default {
    name:"PscActionCodeComponent",
    props: {
      node: Object,
      dept:null,
      pscCode:Object
    },
    methods:{
      selectPscCode(value){
        this.$emit('setPscCode',value)
      }
    }
  }
  </script>

  <style scoped>

  </style>
